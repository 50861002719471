<template>
  <div>
    <users-dialog v-model="showUserDialog" :id="idEdit" @setUser="setUser" :api="'/manager/users'"></users-dialog>
    <dialog-edit2 :value="value" @input="$emit('input')">
      <template v-slot:title
        >{{ data.name ? data.name : "Новый клиент" }}
        <small v-if="id"> ({{ id }})</small>
      </template>
      <v-row class="mt-1 pt-2" style="overflow-y: auto; overflow-x: hidden">
        <v-col v-for="(el, i) in modelForm" :key="i" cols="12" :sm="el.size" class="py-0">
          <div v-for="name in el.name.split('+')" :key="name">
            <a-form-model
              :ref="'field_' + name"
              v-model="data"
              :model="getFormModel([name], model)"
              :errors="errors"
              :config="{ dense: true }"
              @validate="validate($event)"
            />
          </div>
        </v-col>
      </v-row>

      <template v-slot:actions>
        <v-btn v-if="getAccess('user.create')" color="lime accent-2" @click="showUserDialogFun(data.user_id)"
          ><btn-title icon="far fa-user">Пользователь</btn-title></v-btn
        >
        <v-spacer></v-spacer>
        <a-btn-save @click="submit()"></a-btn-save>
        <a-btn-delete v-if="id" @click="_remove()"></a-btn-delete>
        <v-btn @click="$emit('input')">Закрыть</v-btn>
      </template>
    </dialog-edit2>
    <remove-dialog v-model="removeDialogShow" @remove="remove(api, id)" />
    <su-Data ref="suEdit" v-model="suEditShow" :api="api" :id="id" :activate="value" :dataEdit="data" @show="suEditShow = true" v-if="getAccess('suData')" />
  </div>
</template>

<script>
import { getForm, submitForm, removeEl, genModel, getAccess } from "@/components/mixings";

export default {
  components: {
    usersDialog: () => import("@/views/admin/dialogs/usersDialog2"),
  },
  mixins: [getForm, submitForm, removeEl, genModel, getAccess],
  props: {
    value: Boolean,
    id: Number,
    defaults: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      m: this.$store.getters["config/get"].models.clients,
      model: [],
      modelForm: null,
      modelq111: this.$store.getters["config/get"].models.clients.form,
      api: "/mechti/clients",
      removeDialogShow: false,
      showUserDialog: false,
      suEditShow: false,
      idEdit: 0,
    };
  },
  computed: {
    config() {
      return this.m.config.form;
    },
  },
  watch: {
    value() {
      if (this.value) {
        this.updateData(this.id);
        if (!this.id) this.afterFetchData();
      }
    },
  },
  methods: {
    afterFetchData() {
      this.model = this.calcModel();
    },
    calcModel(t) {
      //переназначить функцию, потому что немного отличается от того что в библиотеке genModel
      const config = this.config;
      console.log("run calc", config);
      let model = this.getModelList(this.m, "form", true);
      const fields = config.fields ? config.fields.split(",") : [];
      let fieldsRO = config.fieldsRO ? config.fieldsRO.split(",") : [];
      const fieldsForm = config.fieldsForm ? config.fieldsForm.split(",") : [];
      model = model.filter((el) => {
        return fields.includes(el.name);
      });
      model.forEach((el) => {
        if (fieldsRO.includes(el.name)) {
          el.readonly = true;
        }
      });
      let mForm = [];
      fieldsForm.forEach((field) => {
        let f = field.split("#");
        mForm.push({ name: f[0], size: f[1] || 12 });
      });

      this.modelForm = mForm;
      this.fillFormFromModel(model);
      return model;
    },
    async submit() {
      if (await this.validateAll(this.data)) {
        const resp = await this.$axios.get(this.api, {
          params: {
            filters: {
              name: this.data.name,
              id: { condition: "<>", value: this.data.id },
            },
          },
        });
        let status = resp.data.status == "ok";
        if (!status) {
          this.$root.$emit("show-info", {
            text: "Ошибка....",
          });
          return;
        }
        if (resp.data.data.length) {
          this.errors.name = "Имя занято";
          return;
        }

        await this.post(this.data, this.api);
        return true;
      }
      return false;
    },
    async _remove() {
      if (this.data.status === 2) {
        this.removeDialogShow = true;
        return;
      }
      let data = { id: this.id, status: 2 };
      await this.post(data, this.api);
      this.afterRemove();
    },
    afterRemove(data) {
      this.removeDialogShow = false;
      this.$emit("input");
      this.$store.dispatch("dirs/fitchDirs", "client");
      this.$router.push({ name: "clients" });
    },
    afterSave(data, status) {
      this.$store.dispatch("dirs/fitchDirs", "client");
      if (status) this.$emit("input");
      // this.$router.push({name: "objects_view", params: {id: this.id}});
    },
    showUserDialogFun() {
      this.idEdit = this.data.user_id || 0;
      this.showUserDialog = true;
      console.log(this.idEdit, this.showUserDialog);
    },
    async setUser(e) {
      console.log("setuser", e);
      if (e.id == this.data.user_id) return;
      let status = false;
      this.loading = true;

      let data = { id: this.data.id, user_id: e.id };
      if (this.id) {
        let response = await this.$axios.post(this.api, data);
        status = response.data.status == "ok";
      } else status = true;
      this.loading = false;
      if (status) {
        this.data.user_id = e.id;
        this.data.user_name = e.login;
        this.$root.$emit("show-info", {
          text: "Пользователь привязан",
        });
      } else {
        this.$root.$emit("show-error", {
          text: "Ошибка привяки пользователя",
        });
      }
    },
  },
};
</script>
